.button {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  @apply rounded capitalize border border-transparent transition ease-in-out tracking-wide;
}
.button--default {
  @apply text-white bg-primary-base hover:bg-primary-light;
}
.button--secondary {
  @apply bg-slate-50 text-slate-700 hover:bg-slate-100 border border-slate-500 hover:border-slate-400;
}
.button--active {
  @apply bg-slate-600 border-slate-400;
}

.button--xs {
  @apply px-5 py-2 text-xs;
}
.button--sm {
  @apply px-5 py-2 text-sm;
}
.button--md {
  @apply px-5 py-3.5;
}
.button--lg {
  @apply px-5 py-2 text-lg;
}

:disabled {
  opacity: 0.4;
  cursor: default;
}
